// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.93.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.93.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
#single-spa-application\\:\\@psk\\/app-nav-vue .nav--container[data-v-50441906] {
  position: relative;
  width: 100%;
  height: 100%;
}
#single-spa-application\\:\\@psk\\/app-nav-vue .watermark[data-v-50441906] {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #07070a;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#single-spa-application\\:\\@psk\\/app-nav-vue .watermark--title[data-v-50441906] {
  font-size: 28px;
  font-weight: 600;
}
#single-spa-application\\:\\@psk\\/app-nav-vue header[data-v-50441906] {
  line-height: 1.5;
}
#single-spa-application\\:\\@psk\\/app-nav-vue .logo[data-v-50441906] {
  display: block;
  margin: 0 auto 2rem;
}
@media (min-width: 1024px) {
#single-spa-application\\:\\@psk\\/app-nav-vue header[data-v-50441906] {
    display: flex;
    place-items: center;
    padding-right: calc(var(--section-gap) / 2);
}
#single-spa-application\\:\\@psk\\/app-nav-vue .logo[data-v-50441906] {
    margin: 0 2rem 0 0;
}
#single-spa-application\\:\\@psk\\/app-nav-vue header .wrapper[data-v-50441906] {
    display: flex;
    place-items: flex-start;
    flex-wrap: wrap;
}
}
`, "",{"version":3,"sources":["webpack://./src/App.vue"],"names":[],"mappings":";AAgBA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,QAAQ;EACR,gCAAgC;EAChC,cAAc;EACd,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;AAEA;EACE,gBAAgB;AAClB;AAEA;EACE,cAAc;EACd,mBAAmB;AACrB;AAEA;AACE;IACE,aAAa;IACb,mBAAmB;IACnB,2CAA2C;AAC7C;AAEA;IACE,kBAAkB;AACpB;AAEA;IACE,aAAa;IACb,uBAAuB;IACvB,eAAe;AACjB;AACF","sourcesContent":["<script setup lang=\"ts\">\nimport { IconBrandVue } from '@tabler/icons-vue'\nimport './assets/base.css'\nimport './assets/main.css'\n</script>\n\n<template>\n  <div class=\"nav--container\">\n    <div class=\"watermark\">\n      <IconBrandVue size=\"120\" color=\"#07070a\" />\n      <p class=\"watermark--title\">Vue Sidebar</p>\n    </div>\n  </div>\n</template>\n\n<style scoped>\n.nav--container {\n  position: relative;\n  width: 100%;\n  height: 100%;\n}\n\n.watermark {\n  position: absolute;\n  left: 50%;\n  top: 50%;\n  transform: translate(-50%, -50%);\n  color: #07070a;\n  text-align: center;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.watermark--title {\n  font-size: 28px;\n  font-weight: 600;\n}\n\nheader {\n  line-height: 1.5;\n}\n\n.logo {\n  display: block;\n  margin: 0 auto 2rem;\n}\n\n@media (min-width: 1024px) {\n  header {\n    display: flex;\n    place-items: center;\n    padding-right: calc(var(--section-gap) / 2);\n  }\n\n  .logo {\n    margin: 0 2rem 0 0;\n  }\n\n  header .wrapper {\n    display: flex;\n    place-items: flex-start;\n    flex-wrap: wrap;\n  }\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
